import React from 'react'

const ServiceMobileTags = () => (
  <section className='service-mobile bg-faded py-5'>
    <div className='container'>
      <div className='row mb-5'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right mb-1'>
            <span className='text-muted seo-regular'>Mobile development</span>
            <br />
            <strong>costs</strong>
          </h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <div className='row'>
            <div className='col-sm-4'>
              <strong>Offshore pricing</strong>
              <br />
              high-quality with a small price tag
            </div>
            <div className='col-sm-4'>
              <strong>Time & Materials</strong>
              <br />
              pay in convenient instalments
            </div>
            <div className='col-sm-4'>
              <strong>FREE Bonus</strong>
              <br />
              cost-free project idea estimate
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right'>
            <span className='text-muted seo-regular'>Mobile development</span>
            <br />
            services
          </h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <ul className='list-unstyled list-inline list-badges lead'>
            <li>
              <span className='badge badge-light'>Mobile strategy</span>
            </li>
            <li>
              <span className='badge badge-light'>Business analysis</span>
            </li>
            <li>
              <span className='badge badge-light'>
                Best mobile app design/UX
              </span>
            </li>
            <li>
              <strong className='badge badge-light'>
                Application prototyping
              </strong>
            </li>
            <li>
              <span className='badge badge-light'>
                Cloud and API integration
              </span>
            </li>
            <li>
              <strong className='badge badge-light'>
                Mobile app development
              </strong>
            </li>
            <li>
              <span className='badge badge-light'>QA testing</span>
            </li>
            <li>
              <span className='badge badge-light'>App store publishing</span>
            </li>
            <li>
              <span className='badge badge-light'>Support & Maintenance</span>
            </li>
          </ul>
          <div className='clearfix' />
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-12 col-md-4' />
        <div className='col-sm-12 col-md-8' />
      </div>
    </div>
  </section>
)

export default ServiceMobileTags
