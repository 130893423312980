import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/pro-light-svg-icons'
import smoothScroll from "scroll-to-element"

const ServiceMobileCta = () => (
  <section className='service-mobile cto'>
    <div className='container'>
      <div className='row py-5'>
        <div className='col-xs-12 col-sm-4 col-lg-6'>
          <div className='lead text-left text-sm-right'>
            <h3 className='seo-span seo-strong text-muted'>
              Custom mobile app development Poland
            </h3>
            <br />
            <span className='text-service'>
              for iOS, Android, Windows Phone and wearables
            </span>
            .<br />
            <a
              href='#contact-partial'
              data-form-category='mobileapps'
              className='btn btn-outline-extra1 my-2'
              id='custom-mobile-app-dev-request-btn'
              onClick={event => {
                event.preventDefault()
                smoothScroll('#contact-partial', { duration: 500 })
              }}
            >
              Request for proposal
            </a>
          </div>
        </div>
        <div className='col-xs-12 col-sm-4 col-lg-6'>
          <ul className='fa-ul'>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight}/>
              </span>
              Work with a team of <strong className='seo-regular'>Android developers</strong> and Kotlin specialists
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight}/>
              </span>
              Hire an <strong className='seo-regular'>iOS developer</strong> fluent in Swift
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight}/>
              </span>
              Create a Windows app in .NET
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight}/>
              </span>
              Build mobile, web and hybrid applications
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight}/>
              </span>
              Get a functional and beautiful UI/UX design
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  </section>
)

export default ServiceMobileCta