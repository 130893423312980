import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faApple, faWindows } from '@fortawesome/fontawesome-free-brands'
import { Link } from 'gatsby'

const ServiceMobileOffer = () => (
  <section className='service-mobile'>
    <div className='container'>
      <div className='row py-5'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right mb-2'><span
            className='text-muted seo-regular'>Mobile app</span><br />enterprise solutions</h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <div className='card-group text-center'>
            <div className='card'>
              <div className='card-body'>
                <h3>Mobile development outsourcing</h3>
                <p className='text-sm text-muted text-italic mt-2'>To learn which model would be perfect for you,
                  <Link to='/services/poland-software-outsourcing'>check our outsourcing guide</Link>.
                </p>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <h3>Convert website to mobile app</h3>
                <p className='text-sm text-muted text-italic mt-2'>Take the next step and transform your current
                  platform into a profitable app.</p>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <h3>Custom mobile app development</h3>
                <p className='text-sm text-muted text-italic mt-2'>We are ready to create an app form any category.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row pb-2'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right mb-2'><span
            className='text-muted seo-regular'>Mobile app</span><br /><strong>design solutions</strong></h2>
        </div>
        <div className='col-sm-12 col-md-8 pt-2'>
          <div className='media feature'>
            <div className='feature-icon mr-3'>
              <FontAwesomeIcon icon={faApple} size='2x' className='fa-fw' />
            </div>
            <div className='media-body'>
              <strong className='seo-h3'>iOS app design</strong>
              <p className='text-muted'>Tailored to your needs for iPhones, iPads and Apple Watches, designed to draw
                the customer’s attention in the Apple App Store and to satisfy even the most demanding users.</p>
            </div>
          </div>
          <div className='media feature'>
            <div className='feature-icon mr-3'>
              <FontAwesomeIcon icon={faAndroid} size='2x' className='fa-fw' />
            </div>
            <div className='media-body'>
              <strong className='seo-h3'>Android app design</strong>
              <p className='text-muted'>Maintain a consistent and streamlined user experience within the large Android
                ecosystem. Aimed at the needs of the largest smartphone-user group.</p>
            </div>
          </div>
          <div className='media feature'>
            <div className='feature-icon mr-3'>
              <FontAwesomeIcon icon={faWindows} size='2x' className='fa-fw' />
            </div>
            <div className='media-body'>
              <strong className='seo-h3'>Windows Phone design</strong>
              <p className='text-muted'>For the entire Universal Windows Platform: smartphones, Microsoft Surface and
                devices that run on Windows 10. Become the next big thing in the Windows Store!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer